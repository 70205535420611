import React from 'react';

const Arrow = () => {
    return (
        <svg viewBox="0 0 27.9 24.27">
            <polygon points="0 13.2 24 13.2 12.9 24.27 15.76 24.27 27.9 12.13 15.76 0 12.9 0 24 11.07 0 11.07 0 13.2" fill="#fff" />
        </svg>
    );
};

export default Arrow;