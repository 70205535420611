import React, { useEffect, useState } from 'react';
import ReservationsTable from './ReservationsTable/ReservationsTable';
import { isAlphaNumeric } from 'utils';
import styles from './Page.module.sass';

function PageSession() {
  const licenseKey = localStorage.getItem('licenseKey');
  const [reservationName, setReservationName] = useState('');

  const getReservations = () => {
    fetch(
      `${process.env.REACT_APP_PUBLIC_API_URL}listReservations?key=${licenseKey}`,
    )
      .then((data) => data.json())
      .then((json) => setReservations(json));
  };

  const generateId = () => {
    if (reservationName !== '') {
      fetch(
        `${
          process.env.REACT_APP_PUBLIC_API_URL
        }createReservation?key=${licenseKey}&displayName=${encodeURIComponent(
          reservationName,
        )}`,
      )
        .then((data) => data.json())
        .then((json) => setReservations(reservations.concat(json)));
    } else {
      //alert('Reserved Session Label must be alphanumeric');
    }
  };
  const [reservations, setReservations] = useState([]);

  const deleteReservation = (reservation) => {
    console.log(reservation);

    fetch(
      `${
        process.env.REACT_APP_PUBLIC_API_URL
      }deleteReservation?key=${licenseKey}&displayName=${encodeURIComponent(
        reservation.display_name,
      )}`,
    )
      .then((data) => data.json())
      .then((json) => {
        setReservations((currentReservations) =>
          currentReservations.filter(
            (item) => item.session_id !== reservation.session_id,
          ),
        );
      });
  };

  useEffect(() => {
    getReservations();
  }, []);

  return (
    <div className={styles.root}>
      <div className="columns">
        <section>
          <div className="rich">
            <h2>CREATE A RESERVED SESSION ID</h2>
            <p>
              <strong>
                <i>
                  This tool allows you to generate a Reserved Session ID that
                  you can give to participants before the IzzyCast Session
                  begins. Unlike Single-Use Session IDs, Reserved Session IDs
                  can be used multiple times which makes them ideal for repeated
                  use on the same project.
                </i>
              </strong>
            </p>
            <p>
              <strong>
                <i>
                  Here are two use cases: Generate a Reserved Session ID, send
                  it to the participants, and let them know when you will start
                  the session. Then, at the appointed time, they enter their
                  name and the Reserved Session ID into the Isadora patch you
                  supply to them and click join.
                </i>
              </strong>
            </p>
            <p>
              <strong>
                <i>
                  The second use case is similar, except that you enter the
                  Reserved Session ID into the Isadora Join Session actor and
                  save it with the patch you supply to them. Then they need only
                  enter their name and click “Join”.
                </i>
              </strong>
            </p>
            <p>
              <strong>
                <i>
                  Note: A participant with a Reserved Session ID cannot join a
                  session before the host has started it and they will be
                  ejected from the session after the host leaves.
                </i>
              </strong>
            </p>
            <p>
              <strong>
                <i>
                  To generate a new IzzyCast Reserved Session ID, enter a
                  Session Name and click the „Generate Session ID“ button.”
                </i>
              </strong>
            </p>
          </div>
          <form>
            <input
              type="text"
              id="session_name"
              name="session_name"
              placeholder="Enter Session Name..."
              onChange={(e) => setReservationName(e.target.value)}
            />
            <input
              type="button"
              value="Generate Session ID"
              onClick={() => {
                generateId();
              }}
            />
          </form>
        </section>
        <ReservationsTable
          data={reservations}
          deleteReservation={deleteReservation}
        />
      </div>
    </div>
  );
}
export default PageSession;
