import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ContextUser = createContext();

const licenseKey = localStorage.getItem('licenseKey')
  ? localStorage.getItem('licenseKey')
  : null;

const initialState = {
  loginState: licenseKey ? 'loggedIn' : 'loggedOut',
  licenseKey,
  licenceMinutes: 0,
};

export const WrapperUser = ({ children }) => {
  const [data, setData] = useState(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    getLicenseMinutes();
  }, [data.licenseKey]);

  const getLicenseMinutes = async () => {
    if (!data.licenseKey) return;

    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_API_URL}checkLicense?key=${data.licenseKey}`,
    );
    const json = await response.json();

    if (json.num_minutes) {
      setData({
        ...data,
        licenceMinutes: json.num_minutes,
      });
    }
  };

  const login = async (licenseKey) => {
    setData({
      ...data,
      loginState: 'checkingLicence',
    });

    /*
    const settings = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        'license': licenseKey,
      })
    }

    const response = await fetch(`https://stqb4uw3l0.execute-api.eu-central-1.amazonaws.com/api/check-license`, settings);
    //const response = await fetch(`https://izzycast-staging-api.troikatronix.com:8080/api/check-license`, settings);
    const json = await response.json();

    console.log(json)
    */

    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_API_URL}checkLicense?key=${licenseKey}`,
    );
    const json = await response.json();

    if (json.license_available === true) {
      setData({
        ...data,
        loginState: 'loggedIn',
        licenseKey,
      });

      localStorage.setItem('licenseKey', licenseKey);
    } else {
      setData({
        ...data,
        loginState: 'loggedOut',
      });
    }

    console.log(data);

    /*

    setData({
      ...data,
      licenseKey,
    });
    localStorage.setItem('licenseKey', licenseKey);


    navigate('/reserved-session-id/');

    */
  };

  const logout = () => {
    setData({
      ...data,
      licenseKey: null,
    });
    localStorage.removeItem('licenseKey');
  };

  const isLoggedIn = !!data.licenseKey;

  return (
    <ContextUser.Provider
      value={{
        ...data,
        isLoggedIn,
        login,
        logout,
      }}
    >
      {children}
    </ContextUser.Provider>
  );
};

export function useContextUser() {
  return useContext(ContextUser);
}
