import React, { useEffect, useState } from 'react';
import styles from './Page.module.sass';
import { CartIcon } from 'components/Icons';
import { useContextUser } from 'context/ContextUser';
import StoreOverlay from './StoreOverlay';

const row = {
  count: 1,
  duration: 1,
  people: 2,
};

function PageGetCredits() {
  const { licenseKey, isLoggedIn } = useContextUser();
  const [storeIsOpen, setStoreIsOpen] = useState(false);

  let storedRows = localStorage.getItem('calculator')
    ? JSON.parse(localStorage.getItem('calculator'))
    : [{ ...row }];

  const [rows, setRows] = useState(storedRows);

  const updateItem = (index, type, value) => {
    setRows((prev) =>
      prev.map((row, rowIndex) => {
        if (index === rowIndex) {
          row[type] = value;
        }

        return row;
      }),
    );
  };

  const blurField = (index, type, value) => {
    setRows((prev) =>
      prev.map((row, rowIndex) => {
        if (index === rowIndex) {
          if (type === 'count') {
            if (value < 1) value = 1;
            if (value > 4000) value = 4000;
          }

          if (type === 'duration') {
            if (value < 1) value = 1;
            if (value > 90000) value = 90000;
          }

          if (type === 'people') {
            if (value < 1) value = 2;
            if (value > 16) value = 16;
          }

          row[type] = value;
        }

        return row;
      }),
    );
  };

  const addRow = () => {
    let updatedRows = [...rows];
    updatedRows.push({ ...row });
    setRows(updatedRows);
  };

  const removeRow = (index) => {
    let updatedRows = [...rows];
    updatedRows = updatedRows.filter((row, rowIndex) => rowIndex !== index);

    if (updatedRows.length < 1) {
      updatedRows = [{ ...row }];
    }

    setRows(updatedRows);
  };

  const total = rows.reduce(
    (total, row) => (total += row.count * row.duration * 60 * row.people),
    0,
  );

  useEffect(() => {
    localStorage.setItem('calculator', JSON.stringify(rows));
  }, [rows]);

  const getCredits = (event) => {
    event.preventDefault();
    window.fastspring.builder.tag({ license: licenseKey });
    window.fastspring.builder.update('TopUp-Test', 1);
    window.fastspring.builder.checkout();
  };

  return (
    <>
      <div className={styles.root}>
        <div className="columns">
          <section>
            <h2>Get new IZZYCAST ACCOUNT KEY</h2>
            <div className="rich">
              <p>
                <strong>
                  <i>
                    In order to use IZZYCAST you need an IZZYCAST ACCOUNT KEY.
                    <br />A fresh IzzyCast Account Key can be purchased with an
                    IzzyCast Credit Bundle of 500, 2,500, or 10,000 IZZYCAST
                    CREDITS. Once you have an IzzyCast Account Key you will be
                    able to log in (see top right corner) to manage your
                    Reserved Session IDs, see your History, and get more
                    IzzyCast Credits.
                  </i>
                </strong>
              </p>
            </div>
            <div className="link">
              <span>
                get a new izzycast account key at the{' '}
                <strong>FastSpring</strong> store.
              </span>
              <a
                href="https://fastspringstore.troikatronix.com/"
                target="_blank"
              >
                <CartIcon />
              </a>
            </div>
          </section>
          <section>
            <h2>GET IZZYCAST CREDITS</h2>
            <div className="rich">
              <p>
                <strong>
                  <i>
                    When you have used up the initial amount of IZZYCAST CREDITS
                    on your IzzyCast Account Key or want more, you will have to
                    purchase one or more IZZYCAST CREDIT BUNDLES. In order to do
                    so, you will need to log in with your existing IzzyCast
                    Account Key (see top right corner).
                    <br />
                    You can use our IzzyCast Credit Calculator below to estimate
                    how many IzzyCast Credits you might need for your project.
                  </i>
                </strong>
              </p>
            </div>
            <div className="link">
              <span>
                LOG IN WITH YOUR IZZYCAST ACCOUNT KEY TO GET MORE IZZYCAST
                CREDITS
              </span>
              {isLoggedIn && (
                <>
                  <button onClick={() => setStoreIsOpen(true)} className="text">
                    GET
                    <br />
                    CREDITS
                  </button>
                </>
              )}
            </div>
          </section>
        </div>
        <div className="columns">
          <section></section>
          <section>
            <h2>IZZYCAST CREDIT CALCULATOR</h2>
            <p>
              <strong>
                <i>
                  This calculator allows you to estimate how many IzzyCast
                  Credits you might need for your project so you can record that
                  number and refer to it later while purchasing IzzyCast
                  Credits. To use this calculator, enter the number and duration
                  of your rehearsals and/or performances and the number of
                  participants (including the host) for each.
                  <br />
                  If the number of participants or the length of the rehearsals
                  or performances vary, click the “Add Use Case” button to enter
                  those calculations separately.
                  <br />
                  When sharing 1080p video, the theoretical maximum number of
                  participants is 16.
                  <br />
                  Please note that this calculator doesn’t carry over the
                  credits to the FastSpring Store. To purchase credits, you have
                  to log in with your IzzyCast Account Key at the top right of
                  this page and then click the "GET CREDITS" button above and
                  add the number of credits manually.
                </i>
              </strong>
            </p>
            <div className="calculator">
              <div>
                <div className="row header">
                  <div></div>
                  <div className="label">Sessions</div>
                  <div className="label">Duration (Hours)</div>
                  <div className="label">Participants</div>
                  <div className="label total">Total Credits</div>
                </div>
                {rows.map((row, index) => (
                  <div className="row">
                    <div className="label">Use Case {index + 1}</div>
                    <div>
                      <input
                        type="number"
                        value={row.count}
                        onChange={(e) => {
                          updateItem(index, 'count', e.target.value);
                        }}
                        onBlur={(e) => {
                          blurField(index, 'count', e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        value={row.duration}
                        onChange={(e) => {
                          updateItem(index, 'duration', e.target.value);
                        }}
                        onBlur={(e) => {
                          blurField(index, 'duration', e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        value={row.people}
                        onChange={(e) => {
                          updateItem(index, 'people', e.target.value);
                        }}
                        onBlur={(e) => {
                          blurField(index, 'people', e.target.value);
                        }}
                      />
                    </div>
                    <div className="label total">
                      {row.count * row.duration * 60 * row.people}
                    </div>

                    <span
                      className="icon remove"
                      onClick={() => removeRow(index)}
                    ></span>
                  </div>
                ))}

                <div className="row">
                  <button className="add" onClick={() => addRow()}>
                    add Usecase<span className="icon add"></span>
                  </button>
                </div>
                <div className="row total">
                  <p>Total IzzyCast Credits Required: {total}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <StoreOverlay
        isOpen={storeIsOpen}
        onClose={() => setStoreIsOpen(false)}
      />
    </>
  );
}

export default PageGetCredits;
