import React, { useEffect, useMemo, useState } from 'react';
import Session from './Session';
import styles from './Session.module.sass'
import { ArrowIcon } from 'components/Icons';



// props: data = API call from Zoom
function SessionsTable({ data, itemsPerPage }) {
  const [page, setPage] = useState(0);

  const changePage = (direction) => {
    let pageNew = page + direction

    setPage(pageNew)
  }

  const pagedData = data.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)

  const { previousDisabled, nextDisabled } = useMemo(() => {
    let previousDisabled = page === 0
    let nextDisabled = page * itemsPerPage + itemsPerPage >= data.length

    return {
      previousDisabled,
      nextDisabled
    }

  }, [data, page, itemsPerPage])

  useEffect(() => {
    setPage(0)
  }, [data, itemsPerPage])

  const pages = useMemo(() => {
    let pages = [];

    for(let i=0; i < data.length/itemsPerPage; i++) {
      pages.push(
        <span 
          key={i} 
          onClick={() => setPage(i)}
          className={i === page ? 'active' : ''}
          >{i+1}</span>
      ) 
    }

    return pages;

  }, [data, page, itemsPerPage])

  return (
    <section className={styles.root}>
      <div className="table troikatronix">
        <div className="row head">
          <div>
            <div>Session Name</div>
            <div>Date</div>
            <div>Session ID</div>
          </div>
          <div>
            <div>Session Duration</div>
            <div>Participants</div>
            <div>IzzyCast Credits used</div>
          </div>
        </div>
        {pagedData.map((session, index) => (<Session key={`${index}${session.StartTime}${session.SessionName}`} session={session} />))}
      </div>
      <div className="state">
        <button onClick={() => changePage(-1)} className="previous" disabled={previousDisabled}><ArrowIcon /></button>
        <div className="pages">
          {pages}
        </div>
        <button onClick={() => changePage(1)} className="next" disabled={nextDisabled}><ArrowIcon /></button>
      </div>
    </section>
  );
}
export default SessionsTable;
