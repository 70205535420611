import React from 'react';

const Cart = () => {
    return (
        <svg viewBox="0 0 65.43 56">
            <path d="m24.42,56c3.31,0,6-2.69,6-6s-2.69-6-6-6-6,2.69-6,6,2.69,6,6,6" fill="#fff" />
            <path d="m58.42,56c3.31,0,6-2.69,6-6s-2.69-6-6-6-6,2.69-6,6,2.69,6,6,6" fill="#fff" />
            <polygon points="11.42 7 17.57 35 65.43 29 65.43 7 11.42 7" fill="#fff" />
            <polyline points="0 2 9.42 2 18.42 42.91 65.42 43" fill="none" stroke="#fff" stroke-width="4" />
        </svg>
    );
};

export default Cart;