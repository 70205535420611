import React from 'react';

const Triangle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.39 8.39">
            <polygon fill="#fff" class="cls-1" points="0 8.39 8.39 8.39 8.39 0 0 8.39" />
        </svg>
    );
};

export default Triangle;