import React, { useEffect, useMemo, useState, forwardRef } from 'react';
import SessionsTable from './SessionTable';
import styles from './Page.module.sass';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'components/Dropdown';
import { useContextUser } from 'context/ContextUser';
import { TriangleIcon } from 'components/Icons';

function PageAccount() {
  const [searchValue, setSearchValue] = useState('');

  const { licenseKey } = useContextUser();

  const [endDate, setEndDate] = useState(new Date());
  var currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 3);
  const [startDate, setStartDate] = useState(currentDate);

  const [itemsPerPage, setTtemsPerPage] = useState(25);

  const [order, setOrder] = useState('desc');

  const getSessions = () => {
    let endTime = Math.floor(endDate / 1000);
    let startTime = Math.floor(startDate / 1000);
    fetch(
      `${
        process.env.REACT_APP_PUBLIC_API_URL
      }listSessions?key=${licenseKey}&start=${startTime}&end=${endTime}${
        order === 'asc' ? '&order=asc' : ''
      }`,
    )
      .then((data) => data.json())
      .then((json) => setSessions(json));
  };
  const [sessions, setSessions] = useState({
    Sessions: [],
  });

  const [filteredData, setFilteredData] = useState([]);
  const filterSessions = () => {
    if (
      Math.floor(endDate / 1000 - 24 * 60 * 60) > sessions.End ||
      Math.floor(startDate / 1000) < sessions.Start
    ) {
      getSessions();
    }
  };

  useEffect(() => {
    getSessions();
  }, [order]);

  useEffect(() => {
    filterSessions();
  }, [startDate, endDate, sessions]);

  const filteredSessions = useMemo(() => {
    if (!sessions.Sessions) return [];

    var filtered = sessions.Sessions.filter(
      (session) =>
        session.StartTime < Math.floor(endDate / 1000 + 24 * 60 * 60),
    );
    filtered = filtered.filter(
      (session) =>
        session.StartTime > Math.floor(startDate / 1000 + 24 * 60 * 60),
    );

    if (searchValue) {
      filtered = filtered.filter((session) =>
        session.SessionLabel.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }

    return filtered;
  }, [sessions, searchValue, endDate, startDate]);

  const totalMinutesUsed = useMemo(
    () =>
      sessions.Sessions.reduce(
        (total, session) => (total += session.MinutesUsed),
        0,
      ),
    [sessions],
  );

  const CustomDate = forwardRef(({ value, onClick }, ref) => (
    <button className="date" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <div className={styles.root}>
      <div className="title">
        <h2>Usage Statistics</h2>
        <p className="h2">Total IzzyCast Credits Used: {totalMinutesUsed}</p>
      </div>
      <div className="filters">
        <div>
          <input
            id="session_name_filter"
            type="text"
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Filter Session Name"
          />
        </div>
        <div className="dates">
          <div>
            <label>Start:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomDate />}
            />
          </div>
          <div>
            <label>End:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<CustomDate />}
            />
          </div>
        </div>
        <div className="right">
          <Dropdown
            label={itemsPerPage}
            options={[
              { name: 25, value: 25 },
              { name: 50, value: 50 },
              { name: 100, value: 100 },
            ]}
            onSelect={(values) => {
              const selected = values.find((item) => item.selected === true);
              setTtemsPerPage(selected.value);
            }}
          />
          <span
            className="order"
            style={{
              transform: order === 'asc' ? 'rotate(0deg)' : 'rotate(90deg)',
            }}
            onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
          >
            <TriangleIcon />
          </span>
        </div>
      </div>
      <SessionsTable data={filteredSessions} itemsPerPage={itemsPerPage} />
    </div>
  );
}
export default PageAccount;
