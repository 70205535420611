import { useLocation } from 'react-router-dom';
import styles from './Header.module.sass';
import { navigationMainItems as data } from './data';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useContextUser } from 'context/ContextUser';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Header = () => {
  const [hoverIndex, setHoverIndex] = useState();

  const location = useLocation();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const { licenseKey, licenceMinutes, login, logout, isLoggedIn, loginState } =
    useContextUser();

  const [licenseKeyInput, setLicenseKeyInput] = useState('');

  const onLogin = (event) => {
    event.preventDefault();
    console.log(licenseKeyInput);
    if (licenseKeyInput === '') return;
    login(licenseKeyInput);
  };

  //console.log(hoverIndex)

  let navigationMainItems = data;

  const replace = false;

  if (replace) {
    navigationMainItems = data.map((item) => {
      if (item.subs) {
        item.subs.map((sub) => {
          return sub.items.map((item) => {
            if (item.pathFull) item.path = item.pathFull;

            return item;
          });
        });
      }

      if (item.pathFull) item.path = item.pathFull;

      return item;
    });
  }

  console.log(navigationMainItems);

  const timeout = useRef();

  const onMouseOverMain = (mainIndex) => {
    clearTimeout(timeout.current);
    setHoverIndex(mainIndex);
  };

  const onMouseOverSub = (mainIndex, subIndex) => {
    if (hoverIndex === mainIndex) {
      clearTimeout(timeout.current);
    }
  };

  const { selectedMain, selectedSub } = useMemo(() => {
    let selectedMain = null;
    let selectedSub = null;

    navigationMainItems.forEach((item, mainIndex) => {
      if (item.path === location.pathname) {
        selectedMain = item;
      }

      if (item.subs) {
        item.subs.forEach((subs, subIndex) => {
          let active = subs.items.find((sub, _index) => {
            //console.log(_index, sub.path, location.pathname);
            return sub.path === location.pathname;
          });

          if (active) {
            selectedMain = item;
            selectedSub = active;
          }
        });
      }
    });

    return {
      selectedMain,
      selectedSub,
    };
  }, [location]);

  console.log(selectedMain);
  console.log(selectedSub);

  return (
    <>
      <header
        className={classNames(
          styles.root,
          isInitialized && 'initialized',
          menuIsOpen && 'show-menu',
        )}
      >
        <div className="center main">
          <a href="https://troikatronix.com/home/" id="logo"></a>
          <nav
            onMouseLeave={() => {
              console.log('on mouse out');

              timeout.current = setTimeout(() => {
                setHoverIndex(null);
              }, 500);
            }}
          >
            <ul className="navigation">
              {navigationMainItems.map((item, index) => {
                //console.log(window.location.pathname, item.path)

                return (
                  <li key={item.path}>
                    <Link
                      to={item.path}
                      className={classNames(
                        item.title === selectedMain?.title ? 'selected' : '',
                        item.meta && 'meta',
                      )}
                      data-text={item.title}
                      onMouseOver={() => onMouseOverMain(index)}
                      onClick={() => setHoverIndex(index)}
                    >
                      {item.title}
                    </Link>
                    {item.subs && (
                      <div
                        className={classNames(
                          'sub',
                          (hoverIndex === index ||
                            item.title === selectedMain?.title) &&
                            'selected',
                        )}
                      >
                        {item.subs.map((subs) => {
                          return (
                            <div>
                              <ul>
                                {subs.items.map((sub) => {
                                  if (!isLoggedIn && sub.protected) return null;

                                  return (
                                    <li>
                                      <a
                                        href={sub.path}
                                        data-text={sub.title}
                                        className={
                                          sub.path === selectedSub?.path
                                            ? 'selected'
                                            : ''
                                        }
                                      >
                                        {sub.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
            <div
              className="sub"
              //onMouseOut={() => setHoverIndex(null)}
            >
              {navigationMainItems.map((item, mainIndex) => {
                if (!item.subs) return null;

                return item.subs.map((subs, subIndex) => {
                  let active = subs.items.find((sub, _index) => {
                    //console.log(_index, sub.path, location.pathname);
                    return sub.path === location.pathname;
                  });

                  active && console.log(active);

                  if (hoverIndex && hoverIndex !== mainIndex) active = false;

                  return (
                    <div
                      key={subIndex}
                      className={
                        active || hoverIndex === mainIndex ? 'active' : ''
                      }
                      onMouseOver={() => {
                        onMouseOverSub(mainIndex, subIndex);
                      }}
                      onMouseOut={() => {
                        //onMouseOutSub(mainIndex, subIndex);
                      }}
                    >
                      <ul>
                        {subs.items.map((sub, itemIndex) => {
                          if (
                            (itemIndex > 0 && !active) ||
                            (!isLoggedIn && sub.protected)
                          )
                            return null;

                          return (
                            <li
                              onMouseOver={() => {
                                if (itemIndex === 0 && !active) {
                                  //clearTimeout(timeout.current)
                                }
                              }}
                            >
                              <Link
                                to={sub.path}
                                data-text={sub.title}
                                className={
                                  sub.path === selectedSub?.path
                                    ? 'selected'
                                    : ''
                                }
                              >
                                {sub.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      {active && subIndex === 1 && (
                        <div className={classNames(styles.login, 'login')}>
                          {isLoggedIn ? (
                            <>
                              <span>
                                Account Key:{' '}
                                {licenseKey.substring(0, 15) + '****'}
                              </span>
                              <span>Credit Balance: {licenceMinutes}</span>
                              <button onClick={() => logout()}>Logout</button>
                            </>
                          ) : (
                            <>
                              {loginState === 'checkingLicence' ? (
                                <>
                                  <span>checking licence...</span>
                                </>
                              ) : (
                                <form onSubmit={onLogin}>
                                  <input
                                    style={{ fontSize: '16px' }}
                                    type="text"
                                    id="account_key"
                                    placeholder="Enter Account Key..."
                                    onChange={(e) =>
                                      setLicenseKeyInput(e.target.value)
                                    }
                                  />
                                  <input type="submit" value="Login" />
                                </form>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                });
              })}
            </div>

            <span
              className="marker"
              style={{ left: '0px', width: '88px' }}
            ></span>
          </nav>
          <a
            href="#"
            className="button"
            onClick={(event) => {
              event.preventDefault();
              setMenuIsOpen(!menuIsOpen);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </a>
          <ul className="meta">
            <li>
              <a href="https://troikatronix.com/press/">Press</a>
            </li>
            <li>
              <a href="https://troikatronix.com/dsgvo/">Dsgvo</a>
            </li>
            <li>
              <a href="https://troikatronix.com/impressum/">Impressum</a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Isadora-214094621937491/"
                className="social facebook"
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/troikatronix_isadora/"
                className="social instagram"
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/troikatronix"
                className="social youtube"
              ></a>
            </li>
          </ul>
          {selectedMain && !selectedSub && (
            <div className="state">{selectedMain.title}</div>
          )}
          {selectedSub && <div className="state">{selectedSub.title}</div>}
        </div>
      </header>
      <div className={classNames(styles['login-container'])}>
        <div className={classNames(styles.login, 'login')}>
          {isLoggedIn ? (
            <>
              <span>Account Key: {licenseKey.substring(0, 15) + '****'}</span>
              <span>Credit Balance: {licenceMinutes}</span>
              <button onClick={() => logout()}>Logout</button>
            </>
          ) : (
            <>
              {loginState === 'checkingLicence' ? (
                <>
                  <span>checking licence...</span>
                </>
              ) : (
                <form onSubmit={onLogin}>
                  <input
                    style={{ fontSize: '16px' }}
                    type="text"
                    id="account_key"
                    placeholder="Enter Account Key..."
                    onChange={(e) => setLicenseKeyInput(e.target.value)}
                  />
                  <input type="submit" value="Login" />
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
