import { useRef, useState, useCallback } from 'react';
import { TriangleIcon } from 'components/Icons';
import useOutsideClick from 'utils/hooks/useOutsideClick';
import styles from './Dropdown.module.sass';

const Dropdown = ({
  label,
  options,
  onSelect,
  multiple,
  withSearch,
  className,
}) => {
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef();

  const toggleActive = () => {
    if (!active) {
      setOptionsSelected([...options]);
    }
    setActive(!active);
  };

  const onOutsideClick = useCallback(() => {
    setActive(false);
  }, []);

  useOutsideClick(ref, onOutsideClick, active);

  const handleOptionClick = (selectedItem) => {
    if (multiple) {
      const test = optionsSelected.map((item) => {
        if (item.value === selectedItem.value) {
          item.selected = !item.selected;
        }
        return item;
      });

      setOptionsSelected(test);
      onSelect(test);
    } else {
      onSelect(
        optionsSelected.map((item) => {
          item.selected = item.value === selectedItem.value;
          return item;
        }),
      );
      setActive(false);
    }
  };

  const handleSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  let optionsFiltered = optionsSelected;

  if (searchValue) {
    const searchValueCompare = searchValue.toLowerCase();
    optionsFiltered = optionsFiltered.filter((item) =>
      item.name.toLowerCase().includes(searchValueCompare),
    );
  }

  const classNames = [styles.root];
  className && classNames.push(className);
  active && classNames.push('active');
  if (options.filter((item) => item.selected === true).length)
    classNames.push('selected');

  return (
    <div className={classNames.join(' ')} ref={ref}>
      <span className="button" onClick={toggleActive}>
        show {label} lines
      </span>
      {active && (
        <div>
          {withSearch && (
            <div className="search">
              <input
                type="text"
                value={searchValue}
                onChange={handleSearchValue}
                placeholder="Suchbegriff"
              />
            </div>
          )}
          <div>
            <ul>
              {optionsFiltered.map((item, index) => (
                <li
                  className={item.selected ? 'active' : ''}
                  key={index}
                  onClick={() => handleOptionClick(item)}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.name }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
