import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

function Session(props) {
    var utcSeconds = props.session.StartTime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);

    //console.log(props)

    let start = dayjs.unix(props.session.StartTime)
    //- 100000000000
    let end = dayjs.unix(props.session.EndTime)

    let duration = dayjs.duration(end.diff(start))

    //console.log(duration)

    return (
        <div className="row">
            <div>
            <div>{props.session.SessionLabel}</div>
                <div>{d.toLocaleDateString("en-US") + " " + d.toLocaleTimeString("it-IT")}</div>
                <div>{props.session.SessionName}</div>
            </div>
            <div>
                <div>{duration.format('HH:mm')}</div>
                <div>{props.session.Participants}</div>
                <div>{props.session.MinutesUsed}</div>
            </div>
        </div>
    );
}
export default Session;
