import React, { useMemo, useState } from 'react';
import Reservation from './Reservation';
import styles from './Reservation.module.sass';
import { ArrowIcon } from 'components/Icons';

function ReservationsTable({ data, deleteReservation }) {
  const [order, setOrder] = useState({
    property: 'session_id',
    direction: 1,
  });

  const changeOrder = (column) => {
    const { property, direction } = order;

    if (column === property) {
      setOrder({ ...order, direction: direction * -1 });
    } else {
      setOrder({
        property: column,
        direction: 1,
      });
    }
  };

  const orderedReservations = useMemo(() => {
    const { property, direction } = order;

    return data.sort((a, b) => {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * direction;
    });
  }, [data, order]);

  return (
    <section className={styles.root}>
      <h2>RESERVED SESSION IDS</h2>
      <div className="table troikatronix">
        <div className="row head">
          <div>
            <div>
              <span onClick={() => changeOrder('display_name')}>
                Session Name
                {order.property === 'display_name' && (
                  <span className={order.direction === 1 ? 'asc' : 'desc'}>
                    <ArrowIcon />
                  </span>
                )}
              </span>
            </div>
            <div>
              <span onClick={() => changeOrder('session_id')}>
                Session ID
                {order.property === 'session_id' && (
                  <span className={order.direction === 1 ? 'asc' : 'desc'}>
                    <ArrowIcon />
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        {orderedReservations?.map((reservation) => (
          <Reservation
            key={reservation.session_id}
            reservation={reservation}
            deleteReservation={deleteReservation}
          />
        ))}
      </div>
    </section>
  );
}
export default ReservationsTable;
