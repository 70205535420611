import React from 'react';

function Reservation(props) {
  return (
    <div className="row">
      <div>
        <div>{props.reservation.display_name}</div>
        <div>{props.reservation.session_id}</div>
      </div>

      <span
        className="icon remove"
        onClick={() => props.deleteReservation(props.reservation)}
      ></span>
    </div>
  );
}
export default Reservation;
