import { useEffect } from "react"

const useOutsideClick = (ref, callback, active = true) => {
    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target) || !active) {
                    return;
                }
                callback(event);         
            };
            
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, callback, active]
    );
}

export default useOutsideClick