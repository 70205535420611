const navigationMainItems = [
  {
    title: 'Products',
    width: 108,
    subs: [
      {
        items: [
          {
            title: 'Isadora',
            path: 'https://troikatronix.com/isadora/',
            pathFull: 'https://troikatronix.com/isadora/',
          },
          {
            title: 'Get It',
            path: 'https://troikatronix.com/get-it/',
            pathFull: 'https://troikatronix.com/get-it/',
          },
          {
            title: 'ADD-ONS',
            path: 'https://troikatronix.com/add-ons/',
            pathFull: 'https://troikatronix.com/add-ons/',
          },
        ],
      },
      {
        items: [
          {
            title: 'IzzyCast',
            path: 'https://troikatronix.com/izzycast/',
          },
          {
            title: 'Get It',
            path: '/credit-calculator/',
            pathFull: 'https://izzycast.troikatronix.com/credit-calculator/',
          },
          {
            title: 'Reserved Session ID',
            path: '/reserved-session-id/',
            pathFull: 'https://izzycast.troikatronix.com/credit-calculator/',
            protected: true,
          },
          {
            title: 'History',
            path: '/history/',
            pathFull: 'https://izzycast.troikatronix.com/history/',
            protected: true,
          },
        ],
      },
    ],
  },

  {
    title: 'Forum',
    path: 'https://community.troikatronix.com/',
    pathFull: 'https://community.troikatronix.com/',
    width: 104,
  },
  {
    title: 'Help',
    path: 'https://support.troikatronix.com/support/home',
    pathFull: 'https://support.troikatronix.com/support/home',
    width: 110,
  },
  {
    title: 'Werkstatt',
    path: 'https://troikatronix.com/werkstatt/',
    pathFull: 'https://troikatronix.com/werkstatt/',
    width: 140,
  },
  {
    title: 'Newsletter',
    path: 'https://seu.cleverreach.com/f/13929-62133/',
    pathFull: 'https://seu.cleverreach.com/f/13929-62133/',
    width: 140,
  },
  {
    title: 'Impressum',
    path: 'https://troikatronix.com/impressum/',
    pathFull: 'https://troikatronix.com/impressum/',
    width: 140,
    meta: true,
  },
  {
    title: 'Dsgvo',
    path: 'https://troikatronix.com/dsgvo/',
    pathFull: 'https://troikatronix.com/dsgvo/',
    width: 100,
    meta: true,
  },
  {
    title: 'Press',
    path: 'https://troikatronix.com/press/',
    pathFull: 'https://troikatronix.com/press/',
    width: 100,
    meta: true,
  },
];

export { navigationMainItems };
