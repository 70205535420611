import React, { useEffect, useRef } from 'react';
import styles from './Store.module.sass';
import { useContextUser } from 'context/ContextUser';

const StoreOverlay = ({ isOpen, onClose }) => {
  const { licenseKey, isLoggedIn } = useContextUser();
  const selectRef = useRef();

  const product = {
    id: 'izzycast-credit-bundle-500',
  };

  useEffect(() => {
    if (isOpen) {
      window.fastspring.builder.remove(product.id);
      selectRef.current.value = 0;
    }
  }, [isOpen, product]);

  return (
    <div className={styles.root} style={{ display: isOpen ? 'flex' : 'none' }}>
      <div>
        <span className="close" onClick={() => onClose()}>
          X
        </span>
        <div>
          <h2>IzzyCast Credits</h2>
          <div className="select">
            <select
              ref={selectRef}
              id="quantity"
              data-fsc-item-path-value={product.id}
              data-fsc-item-quantity-value
              onChange={(event) => {
                //alert(event.target.value);
                window.fastspring.builder.update(
                  product.id,
                  event.target.value,
                );
              }}
            >
              {Array.from(Array(51)).map((_, index) => (
                <option value={index}>{index}</option>
              ))}
            </select>

            <span /*data-fsc-item-path={product.id} data-fsc-item-display */>
              IzzyCast 500 Credit Bundle
            </span>
            <span
              class="price"
              data-fsc-item-path={product.id}
              data-fsc-item-price
            ></span>
          </div>
        </div>
        <p className="discount">
          Bulk discounts: 15% at 2,500 credits and 30% at 10,000 credits and
          above.
        </p>
        <div className="total">
          <p>
            Total: <span data-fsc-order-total></span>
          </p>
          <div
            class="radio"
            data-fsc-item-path={product.id}
            data-fsc-item-selection-smartdisplay
          >
            <button
              onClick={() => {
                window.fastspring.builder.tag({ license: licenseKey });
                window.fastspring.builder.checkout();

                setTimeout(() => {
                  onClose();
                }, 3000);
              }}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreOverlay;
