import React from 'react';
import styles from './Footer.module.sass'

const Footer = () => {
    return (
        <footer className={styles.root}>
            
        </footer>
    );
};

export default Footer;