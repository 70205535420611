import { Routes, Route, Navigate } from 'react-router-dom';
import { WrapperUser } from 'context/ContextUser';
import Header from './components/Header';
import PageHome from 'components/PageHome';
import PageGetCredits from './components/PageGetCredits';
import PageSession from './components/PageSession';
import PageAccount from './components/PageAccount';
import PageTemp from 'components/PageTemp';
import Footer from 'components/Footer';
import './App.sass';

import { useContextUser } from 'context/ContextUser';

const Protected = ({ children }) => {
  const { isLoggedIn } = useContextUser();

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};


function App() {
  return (
    <>
      <span className="bg"></span>
      <WrapperUser>
        <div className="page">
          <Header />
          <main>
            <div className="center main">

              <Routes>
                <Route path='/credit-calculator' element={<PageGetCredits />} />
                <Route path='/reserved-session-id'
                  element={
                    <Protected>
                      <PageSession />
                    </Protected>
                  }
                />
                <Route path='/history'
                  element={
                    <Protected>
                      <PageAccount />
                    </Protected>
                  }
                />
                <Route path='/izzycast' element={<PageHome />} />
                <Route path='*' element={<PageTemp />} />


              </Routes>
            </div>
          </main>
        </div>
        <Footer />
      </WrapperUser>
    </>
  );
}

export default App;
